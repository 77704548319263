/* eslint-disable max-len */
/* eslint-disable indent */
import axios from "axios"
import Button from "components/Buttons/Button"
import ButtonIcon from "components/Buttons/ButtonIcon"
import Card from "components/Card/Card"
import { StyledDashboardCardContainer } from "components/Card/Card.styled"
import PhysicalLimit from "components/Card/PhysicalLimit"
import StatusIndicator from "components/Card/StatusIndicator"
import { Charger } from "components/Icons/Charger"
import { ChartThick } from "components/Icons/ChartThick"
import { Eye } from "components/Icons/Eye"
import InfoMarkIcon from "components/Icons/InfoMark"
import { Pen } from "components/Icons/Pen"
import { Restart } from "components/Icons/Restart"
import { Wheel } from "components/Icons/Wheel"
import InputField from "components/InputField/InputField"
import Loader from "components/Loader/Loader"
import PopUp from "components/PopUp/PopUp"
import { Box } from "components/Shared/Box.styled"
import { FlexCol } from "components/Shared/FlexCol"
import { Grid } from "components/Shared/Grid.styled"
import { Label } from "components/Shared/Label.styled"
import { MainPaths } from "components/Shared/MainPaths"
import { Title } from "components/Shared/Title.styled"
import SubMenu from "components/SubMenu/SubMenu"
import {
  Gateway,
  PowerMetersType,
  RestartRequests,
  SubclusterDynamicLm,
} from "components/types/types"
import { useAppContext } from "helpers/AppProvider"
import {
  combinedStatusColor,
  currentDecimals,
  currentToPowerNoneMultiply,
  getCpsInState,
  setSafeLimitColor,
  statusColor,
  statusFormatter,
  toastHandler,
} from "helpers/helpers"
import { useUserContext } from "helpers/UserContextProvider"
import moment from "moment"
import React, { useEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { disconnected, primary } from "styles/colors"
import { bold, semibold, small, standard, xsmall } from "styles/fonts"
import Chargepoints from "Views/Chargepoints/Chargepoints"
import IpScannerModal from "Views/IpScan/IpScanPopup"

interface Props {
  id: string
}

const serviceMap = {
  lmd: "lmStatus",
  proxy: "proxyStatus",
  mmi: "mmiStatus",
}

interface DynamicLMCardProps {
  t: (t: string) => string
  clusterName: string
  isOffline: boolean
  dynamicLoadManagement: SubclusterDynamicLm
  isAdmin: boolean
}

const DynamicLMCard = ({
  t,
  clusterName,
  isOffline,
  dynamicLoadManagement,
  isAdmin,
}: DynamicLMCardProps) => (
  <Card
    offline={isOffline}
    icon={<ChartThick />}
    title={`${t("Load Management")} (${clusterName})`}
    marginBottom={15}
  >
    <Box direction="column" margin="10px 0 0 0">
      <Box direction="row" justify="space-between" align="center">
        <Box direction="column">
          <span
            style={{
              fontWeight: bold,
              fontSize: "30px",
              color: primary,
            }}
          >
            {dynamicLoadManagement === undefined ||
            dynamicLoadManagement.maximumThreshold === undefined ||
            !dynamicLoadManagement.enabled
              ? "-"
              : currentDecimals(dynamicLoadManagement.maximumThreshold, 0)}
            <span style={{ fontSize: "20px" }}>
              {!dynamicLoadManagement.enabled ? "" : "A"}
            </span>
          </span>
          <span style={{ fontSize: "10px", fontWeight: standard }}>
            {t("Grid Connection Point Limit")}
          </span>
        </Box>
        <Box direction="column">
          <span
            style={{
              fontWeight: bold,
              fontSize: "30px",
              color: primary,
            }}
          >
            {dynamicLoadManagement === undefined ||
            dynamicLoadManagement.maxPhase === undefined ||
            !dynamicLoadManagement.enabled
              ? "-"
              : dynamicLoadManagement.maxPhase}
          </span>
          <span style={{ fontSize: "10px", fontWeight: standard }}>
            {t("Maximum Phase")}
          </span>
        </Box>
      </Box>
      <Box direction="row" justify="space-between" align="center">
        <Box direction="column">
          <span
            style={{
              fontWeight: bold,
              fontSize: "30px",
              color: primary,
            }}
          >
            {currentDecimals(dynamicLoadManagement.maxPhyLimit, 0)}
            <span style={{ fontSize: "20px" }}>A</span>
          </span>
          <span style={{ fontSize: "10px", fontWeight: standard }}>
            {t("Physical Limit")}
          </span>
        </Box>
        <Box direction="column">
          <span
            style={{
              fontWeight: bold,
              fontSize: "30px",
              color: primary,
            }}
          >
            {dynamicLoadManagement === undefined ||
            dynamicLoadManagement.dynamicClusterLimit == undefined ||
            !dynamicLoadManagement.enabled
              ? "-"
              : currentDecimals(dynamicLoadManagement.dynamicClusterLimit, 0)}
            <span style={{ fontSize: "20px" }}>
              {!dynamicLoadManagement.enabled ? "" : "A"}
            </span>
          </span>
          <span style={{ fontSize: "10px", fontWeight: standard }}>
            {t("Dynamic Limit")}
          </span>
        </Box>
        <Box direction="column">
          <span
            style={{
              fontWeight: bold,
              fontSize: "30px",
              color: primary,
            }}
          >
            {dynamicLoadManagement === undefined ||
            dynamicLoadManagement.safetyClusterLimit == undefined
              ? "-"
              : currentDecimals(dynamicLoadManagement.safetyClusterLimit, 0)}
            <span style={{ fontSize: "20px" }}>
              {!dynamicLoadManagement.enabled ? "" : "A"}
            </span>
          </span>
          <span style={{ fontSize: "10px", fontWeight: standard }}>
            {t("Safety Limit")}
          </span>
        </Box>
      </Box>
    </Box>
  </Card>
)

const Dashboard = ({ id }: Props) => {
  const [gateway, setGateway] = useState<Gateway[]>([])
  const [subclusters, setSubclusters] = useState<
    {
      id: string
      name?: string
      subclusters?: string[]
      devices: string[]
    }[]
  >([])
  const [pm, setPmData] = useState<PowerMetersType[]>([])
  const [isOffline, setIsOffline] = useState(false)

  const [loadingGateway, setLoadingGateway] = useState(true)
  const [blinkLM, setBlinkLm] = useState(false)
  const [blinkMob, setBlinkMob] = useState(false)
  const [blinkOCPP, setBlinkOCPP] = useState(false)
  const [blinkGw, setBlinkGw] = useState(false)

  const [show, setShow] = useState(false)
  const [scannerShow, setScannerShow] = useState<boolean>(false)

  const [restartReqState, setRestartRequested] = useState<RestartRequests>({
    lmd: false,
    proxy: false,
    mmi: false,
    gwc: false,
  })

  const { t } = useTranslation()
  const { toasts, setToasts, token } = useAppContext()
  const { userInfo } = useUserContext()
  const isAdmin = userInfo.role === "admin"
  const isManaged = userInfo.groups[0]?.role === "managed"

  const nameChanged = useRef<HTMLInputElement | null>(null)

  const gateways = () => {
    axios
      .get(`${process.env.REACT_APP_API_ADDRESS}/gateways/${id}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        Object.keys(serviceMap).forEach((key) => {
          if (restartReqState[key]) {
            if (
              res.data[serviceMap[key]] === "Restarting" ||
              res.data[serviceMap[key]] === "Restart Failed"
            ) {
              res.data[serviceMap[key]] =
                gateway[0][serviceMap[key]] === "Restart failed"
                  ? "Restart failed"
                  : res.data[serviceMap[key]]
              setRestartRequested({
                ...restartReqState,
                [key]: false,
              })
            } else {
              res.data[serviceMap[key]] =
                gateway[0][serviceMap[key]] === "Restart failed"
                  ? "Restart failed"
                  : "Pending"
            }
          }
        })
        setGateway([res.data])

        setLoadingGateway(false)
      })
      .catch((err) => {
        setLoadingGateway(false)
        console.log(err)
      })
  }

  const getSubclusters = () => {
    axios
      .get(`${process.env.REACT_APP_API_ADDRESS}/gateways/${id}/subclusters`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        setSubclusters(res.data)
      })
      .catch((err) => {
        console.log(err)
      })
  }

  const setGatewayName = (name: string) => {
    setLoadingGateway(true)
    axios
      .put(
        `${process.env.REACT_APP_API_ADDRESS}/gateways/${id}`,
        { name: name },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(() => {
        setShow(false)
        setLoadingGateway(false)
        toastHandler(toasts, setToasts, true)
      })
      .catch((err) => {
        setLoadingGateway(false)
        console.log(err)
        toastHandler(toasts, setToasts, false)
      })
  }

  const restartVpn = () => {
    axios
      .post(
        `${process.env.REACT_APP_API_ADDRESS}/gateways/${id}/sendMessage/startVpn`,
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(() => {
        toastHandler(toasts, setToasts, true)
      })
      .catch((err) => {
        console.log(err)
        toastHandler(toasts, setToasts, false)
      })
  }

  useEffect(() => {
    const timers: { [key: string]: NodeJS.Timeout } = {}

    Object.keys(restartReqState).forEach((key) => {
      if (restartReqState[key]) {
        timers[key] = setTimeout(() => {
          setRestartRequested((prevState) => ({ ...prevState, [key]: false }))
        }, 20000)
      }
    })

    return () => {
      Object.values(timers).forEach((timer: NodeJS.Timeout) =>
        clearTimeout(timer)
      )
    }
  }, [restartReqState])

  const restart = (target: string) => {
    if (target === "lmd") {
      setBlinkLm(true)
      setGateway([{ ...gateway[0], lmStatus: "Pending" }])
      setRestartRequested({
        ...restartReqState,
        lmd: true,
      })
      setTimeout(() => {
        setGateway((prevGateway) => {
          if (prevGateway[0].lmStatus === "Pending") {
            return [{ ...gateway[0], lmStatus: "Restart failed" }]
          } else {
            return prevGateway
          }
        })
      }, 15000)
    } else if (target === "ocpp-proxy") {
      setBlinkOCPP(true)
      setGateway([{ ...gateway[0], proxyStatus: "Pending" }])
      setRestartRequested({
        ...restartReqState,
        proxy: true,
      })
      setTimeout(() => {
        setGateway((prevGateway) => {
          if (prevGateway[0].proxyStatus === "Pending") {
            return [{ ...gateway[0], proxyStatus: "Restart failed" }]
          } else {
            return prevGateway
          }
        })
      }, 15000)
    } else if (target === "mmi") {
      setBlinkMob(true)
      setGateway([{ ...gateway[0], mmiStatus: "Pending" }])
      setRestartRequested({
        ...restartReqState,
        mmi: true,
      })
      setTimeout(() => {
        setGateway((prevGateway) => {
          if (prevGateway[0].mmiStatus === "Pending") {
            return [{ ...gateway[0], mmiStatus: "Restart failed" }]
          } else {
            return prevGateway
          }
        })
      }, 15000)
    } else if (target === "gateway") {
      setBlinkGw(true)
    } else {
      console.error(`No such service ${target} for restart`)
      return
    }

    axios
      .post(
        `${process.env.REACT_APP_API_ADDRESS}/gateways/${id}/restart/${target}`,
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(() => {
        toastHandler(toasts, setToasts, true)
      })
      .catch((err) => {
        console.log(err)
        toastHandler(toasts, setToasts, false)
      })
    setTimeout(() => {
      if (target === "lmd") {
        setBlinkLm(false)
      } else if (target === "ocpp-proxy") {
        setBlinkOCPP(false)
      } else if (target === "mmi") {
        setBlinkMob(false)
      } else if (target === "gateway") {
        setBlinkGw(false)
      }
    }, 20000)
  }

  const powermeters = () => {
    axios
      .get(`${process.env.REACT_APP_API_ADDRESS}/powerMeters/${id}`, {
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((res) => {
        if (res.data.length > 0) {
          setPmData(res.data)
        } else {
          setPmData([])
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }

  useEffect(() => {
    powermeters()
    // const interval = setInterval(() => {
    //   powermeters()
    // }, 100000)
    // return () => clearInterval(interval)
  }, [token, id])

  useEffect(() => {
    setLoadingGateway(true)
    gateways()
    getSubclusters()
    const interval = setInterval(() => {
      gateways()
    }, 10000)
    return () => clearInterval(interval)
  }, [token, id, restartReqState])

  useEffect(() => {
    setIsOffline(
      statusFormatter(
        gateway[0]?.gwStatus,
        gateway[0]?.proxyStatus,
        gateway[0]?.lmStatus
      ) === "OFFLINE"
    )
  }, [gateway[0]])

  return (
    <FlexCol>
      {(gateway[0] || loadingGateway) && (
        <Box direction="column" margin="0 0 20px 0">
          <Box direction="row" align="center">
            <Title margin="0">
              {gateway[0]?.name ? gateway[0]?.name : gateway[0]?.id}{" "}
            </Title>
            <ButtonIcon onClick={() => setShow(true)} icon={<Pen />} />
          </Box>
          <Label>{gateway[0]?.id}</Label>
        </Box>
      )}
      {show && (
        <PopUp title={t("Gateway")} setShow={setShow}>
          <Box padding="14px 0 14px 0">
            <InputField
              type="text"
              label={t("Gateway Name")}
              value={gateway[0]?.name ? gateway[0]?.name : gateway[0]?.id}
              innerRef={nameChanged}
              width={100}
            />
          </Box>
          <hr></hr>
          <Box justify="flex-end">
            <Button
              secondary={true}
              onClick={() => setShow(false)}
              text={t("Cancel")}
            />
            <Button
              margin="0 0 0 20px"
              onClick={() =>
                setGatewayName(nameChanged.current?.value as string)
              }
              text={t("Save")}
            />
          </Box>
        </PopUp>
      )}

      <SubMenu items={MainPaths()} id={id} main={true} />
      {loadingGateway || !gateway[0] ? (
        <Loader />
      ) : (
        <>
          <StyledDashboardCardContainer>
            <Card
              icon={<Wheel />}
              title={t("System Status")}
              offline={isOffline}
            >
              <Box direction="column" margin="10px 0 0 0">
                <Grid>
                  <Box direction="column">
                    <span
                      style={{
                        fontWeight: bold,
                        fontSize: "30px",
                        color: combinedStatusColor(
                          gateway[0].gwStatus,
                          gateway[0].proxyStatus,
                          gateway[0].lmStatus
                        ),
                      }}
                    >
                      {statusFormatter(
                        gateway[0].gwStatus,
                        gateway[0].proxyStatus,
                        gateway[0].lmStatus
                      )}
                    </span>
                    <span style={{ fontSize: xsmall, marginTop: "-8px" }}>
                      {t("Status")}
                    </span>
                  </Box>
                  <Box direction="column">
                    <span
                      style={{
                        color: primary,
                        fontSize: xsmall,
                        fontWeight: semibold,
                        marginTop: "8px",
                      }}
                    >
                      {moment(gateway[0].lastUpdate).format("DD.MM.YYYY")}
                    </span>
                    <span
                      style={{
                        color: primary,
                        fontSize: xsmall,
                        fontWeight: semibold,
                      }}
                    >
                      {moment(gateway[0].lastUpdate).format("HH:mm:ss")}
                    </span>

                    <span style={{ fontSize: xsmall, marginTop: "-3px" }}>
                      {t("Last Update")}
                    </span>
                  </Box>
                </Grid>
                {!isOffline &&
                gateway[0].scgType !== undefined &&
                gateway[0].scgType === "gwc" &&
                gateway[0].gatewayInfo !== undefined ? (
                  <>
                    <Box direction="column" margin="10px 0 0 0">
                      <Grid>
                        <Box direction="column">
                          <Box direction="row">
                            <span
                              style={{
                                fontWeight: semibold,
                                fontSize: "14px",
                                color: primary,
                                marginRight: "10px",
                              }}
                            >
                              {gateway[0].gatewayInfo.ipEth.split("/")[0] +
                                ":9000"}
                            </span>
                            {
                              <ButtonIcon
                                icon={<Eye />}
                                onClick={() => {
                                  setScannerShow(true)
                                }}
                                hoverText="Network Scanner"
                                small
                              />
                            }
                          </Box>
                          <span style={{ fontSize: xsmall, marginTop: "-3px" }}>
                            {t("Gateway OCPP URL")}
                          </span>
                        </Box>
                        <Box direction="column">
                          <Box direction="row">
                            <span
                              style={{
                                fontWeight: semibold,
                                fontSize: "14px",
                                color: primary,
                                marginRight: "10px",
                              }}
                            >
                              {gateway[0].gatewayInfo.ipTun.split("/")[0]}
                            </span>
                            {isAdmin && (
                              <ButtonIcon
                                icon={<Restart />}
                                onClick={restartVpn}
                                hoverText="Restart VPN"
                                small
                              />
                            )}
                          </Box>
                          <span style={{ fontSize: xsmall, marginTop: "-3px" }}>
                            {t("VPN IP")}
                          </span>
                        </Box>
                      </Grid>
                      <Grid>
                        <Box direction="column" margin="10px 0 0 0">
                          <span
                            style={{
                              fontWeight: semibold,
                              fontSize: "14px",
                              color: primary,
                            }}
                          >
                            {gateway[0].gatewayInfo.modemsInfo[0].state.toLocaleUpperCase() +
                              (gateway[0].gatewayInfo.modemsInfo[0].state ===
                              "failed"
                                ? ": " +
                                  gateway[0].gatewayInfo.modemsInfo[0]
                                    .stateFailedReason
                                : "")}
                          </span>
                          <span style={{ fontSize: xsmall, marginTop: "-3px" }}>
                            {t("Modem State")}
                          </span>
                        </Box>
                        <Box direction="column" margin="10px 0 0 0">
                          <span
                            style={{
                              fontWeight: semibold,
                              fontSize: "14px",
                              color: primary,
                            }}
                          >
                            {gateway[0].gatewayInfo.modemsInfo[0].signalQuality
                              .value + " %"}
                          </span>
                          <span style={{ fontSize: xsmall, marginTop: "-3px" }}>
                            {t("LTE Signal Strength")}
                          </span>
                        </Box>
                      </Grid>
                    </Box>
                  </>
                ) : (
                  <></>
                )}
                {!isOffline && (
                  <Box direction="column" margin="30px 0 0 0">
                    <Grid>
                      <span style={{ fontSize: small, fontWeight: semibold }}>
                        {t("OCPP Proxy")}
                      </span>

                      {gateway[0].proxyStatus ? (
                        <Box direction="row">
                          <span
                            style={{
                              fontSize: small,
                              fontWeight: semibold,
                              color:
                                gateway[0].gwStatus.toLowerCase() === "active"
                                  ? statusColor(gateway[0].proxyStatus)
                                  : disconnected,
                              marginRight: "10px",
                            }}
                          >
                            {gateway[0].proxyStatus}
                          </span>
                          {!isManaged && (
                            <ButtonIcon
                              icon={<Restart blinking={blinkOCPP} />}
                              onClick={() => restart("ocpp-proxy")}
                              small
                              hoverText="Restart OCPP Proxy"
                              disabled={blinkOCPP}
                            />
                          )}
                        </Box>
                      ) : (
                        <span
                          style={{
                            fontSize: xsmall,
                            fontWeight: semibold,
                          }}
                        >
                          -
                        </span>
                      )}
                      {}
                    </Grid>
                    <Grid>
                      <span style={{ fontSize: small, fontWeight: semibold }}>
                        {t("Load Manager")}
                      </span>
                      <Box direction="row" margin="12px 0 0 0">
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            marginRight: "10px",
                          }}
                        >
                          <span
                            style={{
                              fontSize: small,
                              fontWeight: semibold,
                              color:
                                gateway[0].gwStatus.toLowerCase() === "active"
                                  ? statusColor(gateway[0].lmStatus)
                                  : disconnected,
                            }}
                          >
                            {gateway[0].lmStatus}
                          </span>
                          {gateway[0].lmdStatusContext &&
                            gateway[0].lmdStatusContext.length > 0 &&
                            gateway[0].lmStatus !== "Active" && (
                              <ButtonIcon
                                icon={<InfoMarkIcon />}
                                small
                                onClick={() => null}
                                hoverText={gateway[0].lmdStatusContext.join(
                                  "\n"
                                )}
                                hoverRight
                                hoverBorder
                                disabled
                              />
                            )}
                        </div>
                        {!isManaged && (
                          <ButtonIcon
                            icon={<Restart blinking={blinkLM} />}
                            onClick={() => {
                              restart("lmd")
                            }}
                            small
                            hoverText="Restart Load Manager"
                            disabled={blinkLM}
                          />
                        )}
                      </Box>
                    </Grid>

                    {gateway[0].scgType !== undefined &&
                    gateway[0].scgType === "gwc" ? (
                      <Grid>
                        <span style={{ fontSize: small, fontWeight: semibold }}>
                          {t("Modbus Interface")}
                        </span>
                        <Box direction="row" margin="12px 0 0 0">
                          <span
                            style={{
                              fontSize: small,
                              fontWeight: semibold,
                              color:
                                gateway[0].gwStatus.toLowerCase() === "active"
                                  ? statusColor(gateway[0].mmiStatus)
                                  : disconnected,
                              marginRight: "10px",
                            }}
                          >
                            {gateway[0].mmiStatus}
                          </span>
                          {!isManaged && (
                            <ButtonIcon
                              icon={<Restart blinking={blinkMob} />}
                              onClick={() => restart("mmi")}
                              small
                              hoverText="Restart Modbus Interface"
                              disabled={blinkMob}
                            />
                          )}
                        </Box>
                      </Grid>
                    ) : (
                      <></>
                    )}

                    {!isOffline &&
                    gateway[0].scgType !== undefined &&
                    gateway[0].scgType === "gwc" ? (
                      <Grid>
                        <span style={{ fontSize: small, fontWeight: semibold }}>
                          {t("Gateway CONNECT")}
                        </span>
                        <Box direction="row" margin="12px 0 0 0">
                          <span
                            style={{
                              fontSize: small,
                              fontWeight: semibold,
                              color:
                                gateway[0].gwStatus.toLowerCase() === "active"
                                  ? statusColor(gateway[0].gwStatus)
                                  : disconnected,
                              marginRight: "10px",
                            }}
                          >
                            {gateway[0].gwStatus}
                          </span>
                          {!isManaged && (
                            <ButtonIcon
                              icon={<Restart blinking={blinkGw} />}
                              onClick={() => restart("gateway")}
                              small
                              hoverText="Restart Gateway CONNECT"
                              disabled={blinkGw}
                            />
                          )}
                        </Box>
                      </Grid>
                    ) : (
                      <></>
                    )}
                  </Box>
                )}
              </Box>
            </Card>
            <Card
              offline={isOffline}
              icon={<Charger />}
              title={t("Charging Stations")}
            >
              <Box direction="column" margin="10px 0 0 0">
                <Grid>
                  <Box direction="column">
                    <span
                      style={{
                        fontWeight: bold,
                        fontSize: "30px",
                        color: primary,
                      }}
                    >
                      {gateway[0].chargingStationsNum}
                    </span>
                    <span style={{ fontSize: xsmall, marginTop: "-8px" }}>
                      {t("Charging Stations")}
                    </span>
                  </Box>
                  <Box direction="column">
                    <span
                      style={{
                        fontWeight: bold,
                        fontSize: "30px",
                        color: primary,
                      }}
                    >
                      {gateway[0].connectorsNum}
                    </span>
                    <span style={{ fontSize: xsmall, marginTop: "-8px" }}>
                      {t("Chargepoints")}
                    </span>
                  </Box>
                </Grid>
                <Box
                  direction="column"
                  margin="20px 0 0 0 "
                  style={{ fontWeight: semibold }}
                >
                  <StatusIndicator
                    text={t("Available")}
                    value={getCpsInState(gateway[0].cps, "Available")}
                  />
                  <StatusIndicator
                    text={t("Preparing")}
                    value={getCpsInState(gateway[0].cps, "Preparing")}
                  />
                  <StatusIndicator
                    text={t("Charging")}
                    value={getCpsInState(gateway[0].cps, "Charging")}
                  />
                  <StatusIndicator
                    text={t("Finishing")}
                    value={getCpsInState(gateway[0].cps, "Finishing")}
                  />
                  <StatusIndicator
                    text={t("Suspended EV")}
                    value={getCpsInState(gateway[0].cps, "SuspendedEV")}
                  />
                  <StatusIndicator
                    text={t("Suspended EVSE")}
                    value={getCpsInState(gateway[0].cps, "SuspendedEVSE")}
                  />
                  <StatusIndicator
                    text={t("Faulted")}
                    value={getCpsInState(gateway[0].cps, "Faulted")}
                  />
                  <StatusIndicator
                    text={t("Unavailable")}
                    value={getCpsInState(gateway[0].cps, "Unavailable")}
                  />
                  <StatusIndicator
                    text={t("Init")}
                    value={getCpsInState(gateway[0].cps, "Init")}
                  />
                </Box>
              </Box>
            </Card>
            <Card
              offline={isOffline}
              icon={<ChartThick />}
              title={t("Load Management") + " (Main Cluster)"}
            >
              <Box direction="column" margin="10px 0 0 0">
                <Box direction="row" justify="space-between" align="center">
                  <Box direction="column">
                    <span
                      style={{
                        fontWeight: bold,
                        fontSize: "30px",
                        color: primary,
                      }}
                    >
                      {gateway[0].dynamicLoadManagement === undefined ||
                      gateway[0].dynamicLoadManagement.maximumThreshold ===
                        undefined ||
                      !gateway[0].dynamicLoadManagement.enabled
                        ? "-"
                        : currentDecimals(
                            gateway[0].dynamicLoadManagement.maximumThreshold,
                            0
                          )}
                      <span style={{ fontSize: "20px" }}>
                        {!gateway[0].dynamicLoadManagement.enabled ? "" : "A"}
                      </span>
                    </span>
                    <span style={{ fontSize: "10px", fontWeight: standard }}>
                      {t("Grid Connection Point Limit")}
                    </span>
                  </Box>
                  <Box direction="column">
                    <span
                      style={{
                        fontWeight: bold,
                        fontSize: "30px",
                        color: primary,
                      }}
                    >
                      {gateway[0].dynamicLoadManagement === undefined ||
                      gateway[0].dynamicLoadManagement.maxPhase === undefined ||
                      !gateway[0].dynamicLoadManagement.enabled
                        ? "-"
                        : gateway[0].dynamicLoadManagement.maxPhase}
                    </span>
                    <span style={{ fontSize: "10px", fontWeight: standard }}>
                      {t("Maximum Phase")}
                    </span>
                  </Box>
                </Box>
                <Box direction="row" justify="space-between" align="center">
                  <Box direction="column">
                    <span
                      style={{
                        fontWeight: bold,
                        fontSize: "30px",
                        color: primary,
                      }}
                    >
                      {currentDecimals(
                        gateway[0].dynamicLoadManagement === undefined ||
                          gateway[0].dynamicLoadManagement.fixedClusterLimit ===
                            undefined ||
                          !gateway[0].dynamicLoadManagement.enabled
                          ? gateway[0].maximumCluster.l1
                          : gateway[0].dynamicLoadManagement.fixedClusterLimit,
                        0
                      )}
                      <span style={{ fontSize: "20px" }}>A</span>
                    </span>
                    <span style={{ fontSize: "10px", fontWeight: standard }}>
                      {t("Physical Limit")}
                    </span>
                  </Box>
                  <Box direction="column">
                    <span
                      style={{
                        fontWeight: bold,
                        fontSize: "30px",
                        color: primary,
                      }}
                    >
                      {gateway[0].dynamicLoadManagement === undefined ||
                      gateway[0].dynamicLoadManagement.dynamicClusterLimit ==
                        undefined ||
                      !gateway[0].dynamicLoadManagement.enabled
                        ? "-"
                        : currentDecimals(
                            gateway[0].dynamicLoadManagement
                              .dynamicClusterLimit >
                              gateway[0].dynamicLoadManagement.fixedClusterLimit
                              ? gateway[0].dynamicLoadManagement
                                  .fixedClusterLimit
                              : gateway[0].dynamicLoadManagement
                                  .dynamicClusterLimit,
                            0
                          )}
                      <span style={{ fontSize: "20px" }}>
                        {!gateway[0].dynamicLoadManagement.enabled ? "" : "A"}
                      </span>
                    </span>
                    <span style={{ fontSize: "10px", fontWeight: standard }}>
                      {t("Dynamic Limit")}
                    </span>
                  </Box>
                  {isAdmin && (
                    <Box direction="column">
                      <span
                        style={{
                          fontWeight: bold,
                          fontSize: "30px",
                          color: setSafeLimitColor(gateway[0]),
                        }}
                      >
                        {gateway[0].dynamicLoadManagement === undefined ||
                        gateway[0].dynamicLoadManagement.safetyClusterLimit ==
                          undefined
                          ? "-"
                          : currentDecimals(
                              gateway[0].dynamicLoadManagement
                                .safetyClusterLimit,
                              0
                            )}
                        <span style={{ fontSize: "20px" }}>
                          {!gateway[0].dynamicLoadManagement.enabled ? "" : "A"}
                        </span>
                      </span>
                      <span style={{ fontSize: "10px", fontWeight: standard }}>
                        {t("Safety Limit")}
                      </span>
                    </Box>
                  )}
                </Box>
                <Box
                  direction="column"
                  margin="20px 0 0 0 "
                  style={{ fontWeight: bold }}
                >
                  <PhysicalLimit
                    l1={
                      gateway[0].dynamicLoadManagement === undefined ||
                      gateway[0].dynamicLoadManagement.averagedPhasesBuilding ==
                        undefined ||
                      !gateway[0].dynamicLoadManagement.enabled
                        ? "-"
                        : currentDecimals(
                            Number(
                              gateway[0].dynamicLoadManagement
                                ?.averagedPhasesBuilding?.l1 || 0
                            )
                          )
                    }
                    l2={
                      gateway[0].dynamicLoadManagement === undefined ||
                      gateway[0].dynamicLoadManagement.averagedPhasesBuilding ==
                        undefined ||
                      !gateway[0].dynamicLoadManagement.enabled
                        ? "-"
                        : currentDecimals(
                            Number(
                              gateway[0].dynamicLoadManagement
                                .averagedPhasesBuilding.l2
                            )
                          )
                    }
                    l3={
                      gateway[0].dynamicLoadManagement === undefined ||
                      gateway[0].dynamicLoadManagement.averagedPhasesBuilding ==
                        undefined ||
                      !gateway[0].dynamicLoadManagement.enabled
                        ? "-"
                        : currentDecimals(
                            Number(
                              gateway[0].dynamicLoadManagement
                                .averagedPhasesBuilding.l3
                            )
                          )
                    }
                    total={
                      pm.length === 0
                        ? "-"
                        : currentDecimals(
                            Number(pm[0].lastValues?.realPowerSum)
                          )
                    }
                    showTotal={isAdmin}
                    text={t("External Load")}
                  />
                  <PhysicalLimit
                    l1={
                      gateway[0].dynamicLoadManagement === undefined ||
                      gateway[0].dynamicLoadManagement
                        .averagedPhasesPowerMeter == undefined ||
                      !gateway[0].dynamicLoadManagement.enabled
                        ? "-"
                        : currentDecimals(
                            Number(
                              gateway[0].dynamicLoadManagement
                                ?.averagedPhasesPowerMeter?.l1 || 0
                            )
                          )
                    }
                    l2={
                      gateway[0].dynamicLoadManagement === undefined ||
                      gateway[0].dynamicLoadManagement
                        .averagedPhasesPowerMeter == undefined ||
                      !gateway[0].dynamicLoadManagement.enabled
                        ? "-"
                        : currentDecimals(
                            Number(
                              gateway[0].dynamicLoadManagement
                                .averagedPhasesPowerMeter.l2
                            )
                          )
                    }
                    l3={
                      gateway[0].dynamicLoadManagement === undefined ||
                      gateway[0].dynamicLoadManagement
                        .averagedPhasesPowerMeter == undefined ||
                      !gateway[0].dynamicLoadManagement.enabled
                        ? "-"
                        : currentDecimals(
                            Number(
                              gateway[0].dynamicLoadManagement
                                .averagedPhasesPowerMeter.l3
                            )
                          )
                    }
                    total={
                      pm.length === 0
                        ? "-"
                        : currentDecimals(
                            Number(pm[0].lastValues?.realPowerSum)
                          )
                    }
                    showTotal={isAdmin}
                    text={t("Powermeter")}
                  />
                  <PhysicalLimit
                    l1={currentDecimals(Number(gateway[0].allocated.l1))}
                    l2={currentDecimals(Number(gateway[0].allocated.l2))}
                    l3={currentDecimals(Number(gateway[0].allocated.l3))}
                    total={currentToPowerNoneMultiply(
                      Number(gateway[0].allocated["total"])
                    )}
                    showTotal={isAdmin}
                    text={t("Allocated")}
                  />
                  <PhysicalLimit
                    l1={currentDecimals(Number(gateway[0].actual.l1))}
                    l2={currentDecimals(Number(gateway[0].actual.l2))}
                    l3={currentDecimals(Number(gateway[0].actual.l3))}
                    total={currentToPowerNoneMultiply(
                      Number(gateway[0].actual["total"])
                    )}
                    showTotal={isAdmin}
                    text={t("Actual")}
                  />
                </Box>
              </Box>
            </Card>
          </StyledDashboardCardContainer>
          {gateway[0].subclustersDynamicLM &&
            gateway[0].subclustersDynamicLM.length > 0 && (
              <StyledDashboardCardContainer gridRowGap={10}>
                {gateway[0].subclustersDynamicLM?.map((dynamicLm) => (
                  <DynamicLMCard
                    key={`dynamic-lm-subcluster-${dynamicLm.id}`}
                    dynamicLoadManagement={dynamicLm}
                    clusterName={
                      subclusters.filter((s) => s.id === dynamicLm.id)[0]
                        ?.name || dynamicLm.id
                    }
                    isAdmin={isAdmin}
                    t={t}
                    isOffline={isOffline}
                  />
                ))}
              </StyledDashboardCardContainer>
            )}
        </>
      )}
      <Chargepoints
        offline={isOffline}
        loadingChargePoints={loadingGateway}
        cps={loadingGateway ? [] : gateway[0].cps}
        subclusters={subclusters || []}
      />
      {scannerShow && (
        <IpScannerModal
          ipInfo={gateway && gateway[0] && gateway[0].gatewayInfo}
          gwId={id}
          setShow={(show) => {
            setScannerShow(show)
          }}
        />
      )}
    </FlexCol>
  )
}

export default Dashboard
